.p-0{
  margin: 0 auto;
}

.devicons{
  font-size:1.5em;
      vertical-align: sub;
}

/* ensure items with short summarues push date over image */
.publication-content{
  width:90%;
}


h3{
    margin-top:40px;
}

h3.mb-0{
    margin-top:0;
}

div.skills-heading{
    margin-top:1em;
    margin-bottom:0 !important;
}

/* From https://codepen.io/robinselmer/pen/RarLQK */

.skillbar {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.skillbar-bar:before {
  background: #eee;
  content: "";
  height: 3px;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.skillbar-bar {
  height: 3px;
  width: 0px;
  background: var(--primary-color);
}

.skillbar-title {
  display: inline-block;
}

.skillbar-percent {
  float: right;
  margin-top: 2px;
  display: none;
  font-size: 0.875em;
  font-weight: 600;
}

/* From https://codepen.io/wbeeftink/pen/dIaDH */

.tags {
  font: 12px/1.5 'PT Sans', serif;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.tags li {
  float: left;
}

.tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.tag::before {
  position: absolute;
  left: 7px;
  top: 0px;
  font-family: ForkAwesome;
  content: "\f02b";
  color: #9e9e9e;
}

.tag::after {
  background: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: var(--secondary-color);
  text-decoration: none;
  color: white;
}

.tag:hover::after {
   border-left-color: var(--secondary-color);
}

.tag:hover::before {
  color: white;
}

.project-fact{
  font-size:80%;
  font-style:italic;
}

code {
  background: #ededed;
  color: var(--secondary-color);
  margin: 0 5px;
  padding: 5px 10px;
  border-radius: 15px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 5rem;
  }
  #sideNav .navbar-nav .nav-item .nav-link:not(.active):hover {
    transform: unset !important;
  }
  section.resume-section-sm .img-profile {
    max-width: 60% !important;
  }
  section.resume-section .resume-item .resume-content img {
    max-height: 100% !important;
    max-width: 100% !important;
  }
  section.resume-section .resume-item .resume-date {
    margin-top: 1rem;
    margin-left: 0 !important;
  }
}

.utterances {
  max-width: 100%;
  margin: 0 -4px;
}